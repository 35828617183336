// @font-face {
//   font-family: "Sarabun";
//   src: local("Sarabun"),
//     url(../assets/fonts/Sarabun-Light.ttf) format("truetype");
// }

// @font-face {
//   font-family: "Sarabun-SemiBold";
//   src: local("Sarabun-SemiBold"),
//     url(../assets/fonts/Sarabun-SemiBold.ttf) format("truetype");
// }

// @font-face {
//   font-family: "Sarabun-Bold";
//   src: local("Sarabun-Bold"),
//     url(../assets/fonts/Sarabun-Bold.ttf) format("truetype");
// }

// @font-face {
//   font-family: "Sarabun-Regular";
//   src: local("Sarabun-Regular"),
//     url(../assets/fonts/Sarabun-Regular.ttf) format("truetype");
// }

// button,
// input,
// select,
// textarea,
// a,
// p,
// label,
// span,
// td,
// tr,
// div {
//   font-family: Sarabun;
// }

// h1,
// h2,
// h3 {
//   font-family: Sarabun-SemiBold;
// }

// h4,
// h5 {
//   font-family: Sarabun-Bold;
// }

// .form-group label {
//   font-family: Sarabun-SemiBold;
// }

@font-face {
  font-family: "BaiJamjuree";
  src:
    local("BaiJamjuree"),
    url(../assets/fonts/BaiJamjuree-Light.ttf) format("truetype");
}

@font-face {
  font-family: "BaiJamjuree-SemiBold";
  src:
    local("BaiJamjuree-SemiBold"),
    url(../assets/fonts/BaiJamjuree-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "BaiJamjuree-Bold";
  src:
    local("BaiJamjuree-Bold"),
    url(../assets/fonts/BaiJamjuree-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "BaiJamjuree-Regular";
  src:
    local("BaiJamjuree-Regular"),
    url(../assets/fonts/BaiJamjuree-Regular.ttf) format("truetype");
}

button,
input,
select,
textarea,
a,
p,
label,
span,
td,
tr,
div {
  font-family: BaiJamjuree;
}

h1,
h2,
h3 {
  font-family: BaiJamjuree-SemiBold;
}

h4,
h5 {
  font-family: BaiJamjuree-Bold;
}
.form-group label {
  font-family: BaiJamjuree-SemiBold;
}

.page-item.active .page-link {
  background-color: #39f;
  border-color: #39f;
}

thead {
  background-color: lightblue;
}

.page-link {
  color: #39f;
}

.c-sidebar {
  background-color: #333333;
  //background-color: white;
}

// .c-sidebar-nav-title , .c-sidebar-nav-dropdown-toggle, .c-sidebar-nav-link {
//    color: black !important;

//  }

.c-sidebar-brand-full {
  // background-image: url('./../assets/icons/logo.png');
  width: 80px;
  // background-color: #cccccc;
}

.cursor-pointer {
  cursor: pointer;
}

.c-login-brand {
  width: 200px;
}

.c-sidebar-brand {
  padding-top: 10px;
  padding-bottom: 10px;
}

thead {
  background-color: #ffff;
  color: black;
}

table {
  border: 1.5px solid #eff1f5;
}

.breadcrumb-item {
  a {
    color: #0c4da2 !important;
  }
}

.draftBackGround {
  background-color: white !important;
}

.c-sidebar-nav-item-sub {
  padding-left: 8px;
  font-size: 13px;
}

.local-font {
  font-family: "Kanit", sans-serif !important;
}

.svd_container {
  margin-top: 10px;
}

.svd_container .svd_commercial_container {
  display: none !important;
}

// .svd-popup-editor-tab-general {
//   display: none !important;
// }

question-actions > div > span:nth-child(2),
  question-actions > div > span:nth-child(3),
  question-actions > div > span:nth-child(6),
  question-actions > div > span:nth-child(8),
  question-actions > div > span:nth-child(9)
  // question-actions > div > span:nth-child(6),
  // question-actions > div > span:nth-child(7)
{
  display: none !important;
}

svd-accordion > div > div:nth-child(3),
svd-accordion > div > div:nth-child(4),
svd-accordion > div > div:nth-child(5),
svd-accordion > div > div:nth-child(6),
svd-accordion > div > div:nth-child(7),
svd-accordion > div > div:nth-child(8),
svd-accordion > div > div:nth-child(9),
svd-accordion > div > div:nth-child(10),
svd-accordion > div > div:nth-child(11),
svd-accordion > div > div:nth-child(12),
svd-accordion > div > div:nth-child(13),
svd-accordion > div > div:nth-child(14) {
  display: none !important;
}

.svda-title-editor-placeholder {
  font-size: 20px;
  opacity: 0.8;
}

.modal-container-custom {
  display: flex !important;
}

#svd-survey-settings {
  display: none;
}

.svd-popup-editor-tab-general {
  div.form-group {
    div[data-property="visible"],
      div[data-property="readOnly"],
      div[data-property="showTitle"],
      div[data-property="locale"],
      div[data-property="mode"],
      div[data-property="cookieName"],
      div[data-property="isRequired"],
      div[data-property="hasComment"],
      // div[data-property="inputType"],
      div[data-property="autoComplete"] {
      display: none;
    }
    .svd_custom_select select {
      option[value="color"] {
        display: none !important;
      }
      option[value="datetime"] {
        display: none !important;
      }
      option[value="email"] {
        display: none !important;
      }
      option[value="month"] {
        display: none !important;
      }
      option[value="number"] {
        display: none !important;
      }
      option[value="password"] {
        display: none !important;
      }
      option[value="range"] {
        display: none !important;
      }
      option[value="tel"] {
        display: none !important;
      }
      option[value="url"] {
        display: none !important;
      }
      option[value="week"] {
        display: none !important;
      }
    }
  }
}
.svd-accordion-tab-header[data-title="Validation"] {
  display: none !important;
}
.svd-accordion-tab-header[data-title="Show on Completed"] {
  display: none !important;
}

.svd-accordion-tab-header[data-title="Timer/Quiz"] {
  display: none !important;
}

.modal-dialog-custom {
  width: 700px;
  border: 2px solid #000;
  padding: 16px 32px 24px;
  box-shadow:
    0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  margin: auto;
}

.text-bold {
  font-family: "BaiJamjuree-Bold";
}

.c-sidebar.c-sidebar-light .c-sidebar-brand {
  color: #275395;
  background: #fff;
  font-weight: bold;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle {
  color: #0c4da2;
  background: transparent;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  color: #0c4da2;
  background: transparent;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link:hover,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff;
  background: #0c4da2;
}

// .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle:hover {
// 	color: #fff;
// 	background: #0c4da2;
// }
// .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-items .c-sidebar-nav-item.c-sidebar-nav-item-sub:hover {
// 	color: #fff;
// 	background: #0c4da2;
// }
// .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-items .c-sidebar-nav-item.c-sidebar-nav-item-sub:hover .c-sidebar-nav-link {
// 	color: #fff;
// }

a.nav-link {
  color: #0c4da2 !important;
}
.btn-info {
  // color: #fff;
  background-color: #0c4da2 !important;
  border-color: #0c4da2 !important;
}

.btn-outline-info {
  color: #0c4da2 !important;
  border-color: #0c4da2 !important;
}
.btn-outline-info:hover {
  color: #fff !important;
  border-color: #0c4da2 !important;
  background-color: #0c4da2 !important;
}

.c-app {
  --primary: #0c4da2 !important;
  --info: #0c4da2 !important;
}

.ant-tabs-tab-active,
.text-info {
  color: #0c4da2 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0c4da2 !important;
}

.flex-1 {
  flex: 1;
}

.text-center {
  text-align: center;
}

.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.7) !important;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-icon {
  margin-right: 20px;
  height: 1.09375rem;
}
.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-active,
.c-sidebar.c-sidebar-light .c-active.c-sidebar-nav-dropdown-toggle {
  background-color: #0c4da2 !important;
  color: #fff !important;
}

span.tag-wrapper.ReactTags__tag {
  padding: 2px 10px !important;
  border-radius: 5px !important;
  background: #efefef !important;
  margin-right: 10px !important;
  font-size: 14px !important;
  .ReactTags__remove {
    border: none !important;
    line-height: 0.8px;
    font-size: 16px !important;
    padding: 0 !important;
    color: #979797;
  }
}

.sv_complete_btn {
  display: none !important;
}

#save-survey > div {
  color: #fff !important;
  background-color: #28a745 !important;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-light .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #fff !important;
}

.card {
  background-color: #e4e7ec;
}
.bg-blue {
  background-color: #12284c;
  border-radius: 0px 4px 4px 0px;
}

.btn-warning-custom {
  background: #e35929;
  box-shadow:
    -1px -2px 4px rgba(245, 175, 151, 0.45),
    2px 2px 4px rgba(173, 115, 95, 0.6);
  color: #fff;
}
.c-sidebar.c-sidebar-custom {
  color: #fff;
  background: #12284c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-right: 1px solid rgba(159, 167, 179, 0.5);
}

.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: #fff;
  background: #1c4489;
}

.ql-editor {
  min-height: 300px;
  background-color: #fff;
}

.table-striped tbody tr:nth-of-type(2n) {
  background-color: #fff;
}

.page-item.active .page-link {
  background-color: #1c4489;
  border-color: #1c4489;
}
.page-link {
  color: #1c4489;
}

.btn {
  border-radius: 4px !important;
  box-shadow:
    4px 4px 10px rgba(192, 194, 196, 0.6),
    -4px -4px 10px rgba(255, 255, 255, 0.45);
}

.btn-primary-custom {
  color: #fff;
  background-color: #1c4489;
}

.btn-primary-custom:hover {
  background-color: #fff;
  color: #1c4489;
}

.btn-reverse-primary-custom {
  background-color: #e4e7ec;
  color: #1c4489;
}

.btn-reverse-primary-custom:hover {
  color: #fff;
  background-color: #1c4489;
}
.card-section-header {
  padding: 0 15px 15px 15px;
  font-size: 1.5rem;
  border-bottom: 2px solid #e35929;
}
.c-header {
  background: #e8ebf0;
  border-bottom: 1px solid #d8dbe0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.c-body {
  background-color: #fff;
}

.c-switch-slider {
  background-color: #c4c9d0;
  border-color: #c4c9d0;
}

.imgPreview {
  width: "20px";
  border: solid #ffffff 5px;
  background-color: #ffffff;
}

.imageTag {
  width: 100%;
  height: auto;
}

.c-previewImage {
  width: 150px;
  height: 150px;
  background-color: #eeeeee;
  border-radius: 50%;
  padding: 6px;
}

.btn-reverse-danger-custom {
  background-color: #e4e7ec;
  color: #da3833;
}

.btn-reverse-danger-custom:hover {
  color: #fff;
  background-color: #da3833;
}

.btn-reverse-success-custom {
  background-color: #e4e7ec;
  color: #2eb85c;
}

.btn-reverse-success-custom:hover {
  color: #fff;
  background-color: #2eb85c;
}

.btn-reverse-warning-custom {
  background-color: #e4e7ec;
  color: #f9b115;
}

.btn-reverse-warning-custom:hover {
  color: #fff;
  background-color: #f9b115;
}

.form-control {
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.c-sidebar-nav-title {
  text-transform: none !important;
}

.ck-editor__editable {
  min-height: 300px;
}

.c-changetype {
  position: absolute;
  right: 25px;
  bottom: 25px;
  cursor: pointer;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
